import React from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./modal.css";

const Modal = ({ isOpen, title, onRequestClose, children }) => {
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content" overlayClassName="modal-overlay">
      {title ? (
        <div className="modal-content-title">
          <h3>
            {title}
            <span className="modal-content-close" onClick={onRequestClose}>
              <FontAwesomeIcon icon="times" />
            </span>
          </h3>
          <hr />
        </div>
      ) : null}
      {children}
    </ReactModal>
  );
};

export default Modal;
