import React from "react";
import { Pagetitle } from "../../components";

const Dashboard = () => {

  return (
    <>
      <Pagetitle icon="home" title="Dashboard" />
    </>
  );
};

export default Dashboard;
