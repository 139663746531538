import React from "react";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./dnd-calendar.css";

// CALENDAR VARIABLES
moment.updateLocale("de", {
  months: "Januar Februar März April Mai Juni Juli August September Oktober November Dezemeber".split(" "),
  monthsShort: "Jan Feb Mär Apr Mai Jun Jul Aug Sep Okt Nov Dez".split(" "),
  monthsParseExact: true,
  weekdays: "Sonntag Montag Dienstag Mittwoch Donnerstag Freitag Samstag".split(" "),
  weekdaysShort: "So Mo Di Mi Do Fr Sa".split(" "),
  weekdaysMin: "So Mo Di Mi Do Fr Sa".split(" "),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD.MM.YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Heute] LT",
    nextDay: "[Morgen] LT",
    nextWeek: "[nächste Woche] LT",
    lastDay: "[Gestern] LT",
    lastWeek: "[letzte Woche] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "in %s",
    past: "vor %s",
    s: "wenigen Sekunden",
    m: "einer Minute",
    mm: "%d Minuten",
    h: "einer Stunde",
    hh: "%d Stunden",
    d: "einem Tag",
    dd: "%d Tage",
    M: "einem Monat",
    MM: "%d Monate",
    y: "einem Jahr",
    yy: "%d Jahre",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function ordinal(number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function isPM(input) {
    return input.charAt(0) === "M";
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/_id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function meridiem(hours) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 1, // The week that contains Jan 4th is the first week of the year.
  },
});

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const DndCalendar = ({ events, onEventDrop, onSelectSlot, onSelectEvent, onEventResize }) => {
  return (
    <DragAndDropCalendar
      selectable
      localizer={localizer}
      events={events}
      onEventDrop={onEventDrop}
      resizable={true}
      onEventResize={onEventResize}
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      defaultView={Views.MONTH}
      defaultDate={new Date()}
    />
  );
};

export default DndCalendar;
