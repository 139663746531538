import Card from "./Card/card";
import DndCalendar from "./DndCalendar/dnd-calendar";
import Modal from "./Modal/modal";
import Pagetitle from "./Pagetitle/pagetitle";
import OutsideAlerter from "./OutsideAlerter/outside-alerter";

const toDateString = (timestamp) => {
  var date = new Date(1970, 0, 1);
  date.setSeconds(timestamp);
  const day = date.getDate();
  const month = date.getMonth();
  const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  const year = date.getFullYear();

  return `${day}. ${months[month]} ${year}`;
};

const toTimeString = (timestamp) => {
  var date = new Date(1970, 0, 1);
  date.setSeconds(timestamp);
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return hours + ":" + minutes;
};

export { Card, DndCalendar, Modal, Pagetitle, OutsideAlerter, toDateString, toTimeString };
