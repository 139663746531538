import React from "react";
import "./card.css";

const Card = props => {
  return (
    <div className="card" {...props}>
      {props.title ? (
        <div className="card-header">
          <h3>{props.title}</h3>
        </div>
      ) : null}
      <div className="card-body">{props.children}</div>
    </div>
  );
};

export default Card;
