import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../Layout/layout";
import { GlobalContext } from "../../context/store";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const context = useContext(GlobalContext);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!context.user.get ? (
          <Layout>
            <RouteComponent {...routeProps} />
          </Layout>
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
