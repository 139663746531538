import React, { useContext, useState } from "react";
import { Pagetitle, Card, Modal } from "../../components";
import { GlobalContext } from "../../context/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import base from "../../config/base";
import { toast } from "react-toastify";

const Partners = () => {
  const { partners } = useContext(GlobalContext);

  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  const [logo, setLogo] = useState(null);

  const [modalId, setModalId] = useState('');
  const [modalName, setModalName] = useState('');
  const [modalLink, setModalLink] = useState('');
  const [modalURL, setModalUrl] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const addPartner = () => {
    console.log(logo);
    setModalLoading(true);
    if (name !== '') {
      if (link !== '') {
        if (logo !== null) {
          base.firestore().collection('partners').add({
            name,
            link,
          }).then(doc => {
            base.storage().ref(`partners/${doc.id}`).put(logo).then(snapshot => {
              snapshot.ref.getDownloadURL().then(url => {
                base.firestore().doc(`partners/${doc.id}`).update({
                  logo: url,
                }).then(() => {
                  toast.success('Partner hinzugefügt');
                  setLogo(null);
                  setModalOpen(false);
                  setModalLoading(false);
                }).catch(() => toast.error('Internal Error'));
              }).catch(() => toast.error('Internal Error'));
            }).catch(() => toast.error('Internal Error'));
          }).catch(() => toast.error('Internal Error'));
        } else {
          toast.error('Logo is required');
        }
      } else {
        toast.error('Link is required');
      }
    } else {
      toast.error('Name is required');
    }
  }

  const updatePartner = () => {
    if (modalName !== '') {
      if (modalLink !== '') {
        if (logo) {
          base.storage().ref(`partners/${modalId}`).put(logo).then(snapshot => {
            snapshot.ref.getDownloadURL().then(url => {
              base.firestore().doc(`partners/${modalId}`).update({
                name: modalName,
                link: modalLink,
                logo: url,
              }).then(() => {
                toast.success('Partner aktualisiert');
                resetModalValue();
              }).catch(() => toast.error('Internal Error'));
            }).catch(() => toast.error('Internal Error'));
          }).catch(() => toast.error('Internal Error'));
        } else {
          base.firestore().doc(`partners/${modalId}`).update({
            name: modalName,
            link: modalLink,
          }).then(() => {
            toast.success('Partner aktualisiert');
            setModalOpen(false);
            setModalLoading(false);
          })
            .catch(() => toast.error('Internal Error'));
        }
      } else {
        toast.error('Link is required');
      }
    } else {
      toast.error('Name is required');
    }
  }

  const deletePartner = () => {
    if (modalURL) { 
      base.storage().ref(`partners/${modalId}`).delete().then(() => {
        base.firestore().doc(`partners/${modalId}`).delete().then(() => {
          resetModalValue();
          toast.success('Partner gelöscht');
        }).catch(() => toast.error('Internal Error'));
      }).catch(() => toast.error('Internal Error'));
    } else {
      base.firestore().doc(`partners/${modalId}`).delete().then(() => {
        resetModalValue();
        toast.success('Partner gelöscht');
      }).catch(() => toast.error('Internal Error'));
    }
  }

  const setModalValues = item => {
    setModalId(item.id);
    setModalName(item.name);
    setModalLink(item.link);
    setModalUrl(item.logo);
    setModalOpen(true);
  }

  const resetModalValue = () => {
    setModalId('');
    setModalName('');
    setModalLink('');
    setModalUrl(null);
    setModalOpen(false);
    setModalLoading(false);
  }

  return (
    <>
      <Pagetitle icon="building" title="Partner" />

      <div className="mb-30">
        <button onClick={() => setModalOpen(true)} className="btn">
          <FontAwesomeIcon icon="plus" /> Partner hinzufügen
        </button>
      </div>

      <Card>
        <table className="hover">
          <thead>
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {
              partners.get.map((item, i) => {
                return (
                  <tr key={i} onClick={() => setModalValues(item)}>
                    <td><img src={item.logo} alt="Partner-Logo" /></td>
                    <td>{item.name}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={item.link}>{item.link}</a></td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </Card>

      <Modal title="Neuer Partner" isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <input type="text" className="form" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
        <input type="text" className="form" placeholder="Website-Link" value={link} onChange={e => setLink(e.target.value)} />
        <label htmlFor="partner-logo">
          <b>Partner-Logo:</b> <input id="partner-logo" type="file" onChange={e => setLogo(e.target.files[0])} />
        </label>
        <br />
        {
          modalLoading ? (
            <div className="loading"><div></div><div></div><div></div></div>
          ) : (
            <button className="btn mb-3" onClick={addPartner}>Partner hinzufügen</button>
          )
        }
      </Modal>

      <Modal title="Partner bearbeiten" isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <input type="text" className="form" placeholder="Name" value={modalName} onChange={e => setModalName(e.target.value)} />
        <input type="text" className="form" placeholder="Website-Link" value={modalLink} onChange={e => setModalLink(e.target.value)} />
        <label htmlFor="partner-logo">
          <b>Partner-Logo:</b> <input id="partner-logo" type="file" onChange={e => setLogo(e.target.files[0])} />
        </label>
        <br />
        {
          modalLoading ? (
            <div className="loading"><div></div><div></div><div></div></div>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <button className="btn mb-3" onClick={updatePartner}>Aktualisieren</button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-danger mb-3" onClick={deletePartner}>Löschen</button>
              </div>
            </div>
          )
        }
      </Modal>
    </>
  );
};

export default Partners;
