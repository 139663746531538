import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './pagetitle.css';


const Pagetitle = props => {
  return (
    <div className="card pagetitle">
      <FontAwesomeIcon icon={props.icon} />
      <h1>{props.title}</h1>
    </div>
  );
};

export default Pagetitle;
