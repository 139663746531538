import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


const base = Firebase.initializeApp({
  apiKey: "AIzaSyDdvwG21IcWED9mhj_aWVyrCkBDuo_YqoM",
  authDomain: "schoensee-loosen-datentechnik.firebaseapp.com",
  databaseURL: "https://schoensee-loosen-datentechnik.firebaseio.com",
  projectId: "schoensee-loosen-datentechnik",
  storageBucket: "schoensee-loosen-datentechnik.appspot.com",
  messagingSenderId: "870521612815",
  appId: "1:870521612815:web:474b44638085c96e84117a",
  measurementId: "G-NJVFXEBE6Q"
});

export default base;
