import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./sidebar.css";

const Sidebar = () => {
  return (
    <>
      <div className="sidebar-logo">
        <h1>SLD-CMS</h1>
      </div>
      <div className="sidebar-content">
        <ul>
          <li>
            <NavLink exact to="/">
              <FontAwesomeIcon icon="home" />
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/articles">
              <FontAwesomeIcon icon="newspaper" />
              Beiträge
            </NavLink>
          </li>
          <li>
            <NavLink to="/media">
              <FontAwesomeIcon icon="photo-video" />
              Mediathek
            </NavLink>
          </li>
          <li>
            <NavLink to="/members">
              <FontAwesomeIcon icon="users" />
              Mitarbeiter
            </NavLink>
          </li>
          <li>
            <NavLink to="/partners">
              <FontAwesomeIcon icon="building" />
              Partner
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
