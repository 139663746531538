import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { GlobalContext, logOut } from '../../context/store';
import { OutsideAlerter } from '../../components';
import './header.css';


const mql = window.matchMedia(`(max-width: 500px)`);

const Header = () => {

  const { sidebarOpen, userData } = useContext(GlobalContext);

  // States
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Functions
  const openDropdown = () => {
    setDropdownOpen(true);
  }
  const closeDropdown = () => {
    setDropdownOpen(false);
  }

  return (
    <header style={sidebarOpen.get ? { paddingLeft: '255px' } : { paddingLeft: '15px' }}>
      <div className="header-bars" onClick={sidebarOpen.toggle}>
        <FontAwesomeIcon icon="bars" size="lg" />
      </div>
      <div className="header-user" onClick={openDropdown}>
        <div className="header-userimage">
          <img src={ userData.get ? userData.get.photoURL : null } alt="profile" />
        </div>
        <div className="header-username" style={ mql.matches && sidebarOpen.get ? { display: 'none' } : { display: 'block' }}>
          { userData.get ? userData.get.firstname + ' ' + userData.get.lastname : null }
        </div>
      </div>
      <OutsideAlerter func={closeDropdown}>
        <div className="header-user-dropdown" style={dropdownOpen ? { visibility: 'visible' } : { visibility: 'hidden' }}>
          <img src={ userData.get ? userData.get.photoURL : null } alt="profile"/>
          <h4 className="text-center">{ userData.get ? userData.get.firstname + ' ' + userData.get.lastname : null }</h4>
          <br/>
          <div className="divider"></div>
          <ul>
            <li><Link to="/profile"><FontAwesomeIcon icon="user" />Profil</Link></li>
            <li><Link to="/settings"><FontAwesomeIcon icon="cog" />Einstellungen</Link></li>
          </ul>
          <div className="divider"></div>
          <ul>
            <li><Link to="/login" onClick={logOut}><FontAwesomeIcon icon="sign-out-alt" />Abmelden</Link></li>
          </ul>
        </div>
      </OutsideAlerter>
    </header>
  );
};

export default Header;