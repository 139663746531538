import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/store";
import { Card, Pagetitle, toDateString } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./articles.css";

const Articles = props => {
  const { articles } = useContext(GlobalContext);

  return (
    <>
      <Pagetitle icon="newspaper" title={"Beiträge (" + articles.get.length + ")"} />

      <div className="mb-30">
        <Link to="/articles/create" className="btn">
          <FontAwesomeIcon icon="plus" /> Neuer Beitrag
        </Link>
      </div>

      <Card>
        <table className="hover">
          <thead>
            <tr>
              <th>Beitragsbild</th>
              <th>Titel</th>
              <th>Autor</th>
              <th>Erstellt</th>
            </tr>
          </thead>
          <tbody>
            {articles.get.map((article, i) => {
              return (
                <tr key={article.id} onClick={() => props.history.push("/articles/edit/" + article.id)}>
                  <td><img src={article.image} alt="Beitragsbild" width="128" /></td>
                  <td><b>{article.title}</b></td>
                  <td>{article.author}</td>
                  <td>{toDateString(article.createdAt.seconds)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default Articles;
