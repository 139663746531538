import React, { useCallback, useContext, useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import { GlobalContext } from '../../context/store';
import { Card } from '../../components';
import app from '../../config/base';


const Login = ({ history }) => {

  const [loading, setLoading] = useState(false);

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);
      const { username, password } = event.target.elements;
      try {
        await app.auth().signInWithEmailAndPassword(username.value, password.value);
        history.push('/');
      } catch (error) {
        console.log(error);
      }
    },
    [history]
  );

  const { user } = useContext(GlobalContext);

  if (user.get) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div className="container mt-100">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <Card title="Anmelden">
              <form onSubmit={handleLogin}>
                <input type="text" className="form" name="username" placeholder="Benutzername" />
                <input type="password" className="form" name="password" placeholder="Passwort" />
                <button type="submit" className="btn w-100" disabled={loading}>Anmelden</button>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Login);