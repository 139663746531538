import React, { useContext } from "react";
import ReactModal from "react-modal";
import { GlobalContext } from "../../context/store";
import SidebarWrapper from "react-sidebar";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "./layout.css";

const Layout = (props) => {
  const { sidebarOpen, sidebarDocked } = useContext(GlobalContext);

  ReactModal.setAppElement("#root");

  return (
    <SidebarWrapper
      sidebar={<Sidebar />}
      open={sidebarOpen.get}
      docked={sidebarDocked.get}
      onSetOpen={sidebarOpen.set}
      sidebarClassName="sidebar-nav"
      shadow={false}
    >
      <Header />
      <main>{props.children}</main>
    </SidebarWrapper>
  );
};

export default Layout;
