import React, { useRef, useEffect } from "react";


const useOutsideAlerter = (ref, func) => {

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      func();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter = props => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.func);

  return <div ref={wrapperRef}>{props.children}</div>;
}

export default OutsideAlerter;