import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import GlobalProvider from "../context/store";
import PrivateRoute from "../hoc/PrivateRoute/privateRoute";

// Routes
import Dashboard from "./Dashboard/dashboard";
import Articles from "./Articles/articles";
import ArticleCreate from "./Articles/article-create";
import ArticleEdit from "./Articles/article-edit";
import Members from "./Members/members";
import Media from "./Media/media";
import Partners from './Partners/partners';
import Login from "./Login/login";
import Error from "./Error/error";

// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faSignOutAlt,
  faUser,
  faCog,
  faHome,
  faNewspaper,
  faPhotoVideo,
  faCalendarAlt,
  faUsers,
  faChild,
  faCloud,
  faPlus,
  faPaperPlane,
  faArrowLeft,
  faTimes,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";


library.add(
  fab,
  faBars,
  faSignOutAlt,
  faUser,
  faCog,
  faHome,
  faNewspaper,
  faPhotoVideo,
  faCalendarAlt,
  faUsers,
  faChild,
  faCloud,
  faPlus,
  faPaperPlane,
  faArrowLeft,
  faTimes,
  faBuilding,
);

const App = () => {
  return (
    <GlobalProvider>
      <ToastContainer />
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/articles" component={Articles} />
          <PrivateRoute exact path="/articles/create" component={ArticleCreate} />
          <PrivateRoute exact path="/articles/edit/:id" component={ArticleEdit} />
          <PrivateRoute exact path="/media" component={Media} />
          <PrivateRoute exact path="/members" component={Members} />
          <PrivateRoute exact path="/partners" component={Partners} />

          <Route exact path="/login" component={Login} />

          <PrivateRoute component={Error} />
        </Switch>
      </Router>
    </GlobalProvider>
  );
};

export default App;
