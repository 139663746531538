import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../context/store';
import { Pagetitle, Card, toDateString, toTimeString } from '../../components';
import { Editor } from '@tinymce/tinymce-react';
import app from '../../config/base';


const ArticleCreate = props => {

  const { userData } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [article, setArticle] = useState({
    title: '',
    content: '',
    categories: ['Allgemein'],
    image: '',
    photo: null,
    author: '',
    createdAt: Date.now(),
  });

  const handleInputChange = e => setArticle({
    ...article,
    [e.currentTarget.name]: e.currentTarget.value,
  });

  const handleImageChange = e => setArticle({
    ...article,
    photo: e.target.files[0],
  });

  const validateForm = () => {
    if (article.title.trim() === '') {
      toast.error('Titel ist erforderlich');
      return false;
    }
    if (article.content.trim() === '') {
      toast.error('Nachricht ist erforderlich');
      return false;
    }
    if (article.photo && article.photo.type !== 'image/jpeg') {
      toast.error('Keine gültige JPEG-Datei');
      return false;
    }
    return true;
  }

  const handleUpload = () => {
    if (validateForm()) {
      setLoading(true);
      app.firestore().collection('articles').add({
        title: article.title,
        categories: article.categories,
        content: article.content,
        author: article.author,
        image: article.image,
      }).then((doc) => {
        if (article.photo) {
          const suffixArray = article.photo.name.split('.');
          const suffix = suffixArray[suffixArray.length - 1].toLowerCase();
          app.storage().ref(`/articles/${doc.id}.${suffix}`).put(article.photo).then(() => {
            app.storage().ref(`/articles/${doc.id}.${suffix}`).getDownloadURL().then(image => {
              app.firestore().doc('/articles/' + doc.id).set({ image }, { merge: true }).then(() => {
                setLoading(false);
                toast.success('Beitrag erstellt');
                props.history.push('/articles');
              });
            });
          });
        } else {
          setLoading(false);
          toast.success('Beitrag erstellt');
          props.history.push('/articles');
        }
      }).catch(error => {
        setLoading(false);
        toast.error(error);
      });
    }
  };

  useEffect(() => {
    if (userData.get.firstname && userData.get.lastname && article.author === '') {
      setArticle({
        ...article,
        author: userData.get.firstname + ' ' + userData.get.lastname,
      });
    }
  }, [article, userData.get.id, userData.get.firstname, userData.get.lastname]);

  return (
    <>
      <Pagetitle icon="newspaper" title="Beitrag erstellen" />

      <Link to="/articles" className="btn btn-transparent mb-30">
        <FontAwesomeIcon className="mr-2" icon="arrow-left" />Alle Beiträge
      </Link>

      <div className="row">
        <div className="col-md-9">
          <Card>
            <input type="text" className="form m-0" name="title" value={article.title} onChange={handleInputChange} placeholder="Titel" />
          </Card>
          <Card>
            <Editor
                apiKey="82s1jwskr7fan26fsy1jmeepsqgzed5euchlzngruckiweuu"
                value={article.content}
                onEditorChange={(content) => setArticle({
                  ...article,
                  content,
                })}
                init={{
                  height: '500px',
                  menu: {
                    view: { title: 'View', items: 'code' },
                  },
                  plugins: 'code image',
                  menubar: 'file edit insert view format',
                }}
              />
          </Card>
        </div>
        <div className="col-md-3">
          <Card>
            <table>
              <tbody>
                <tr>
                  <td>Erstellt:</td>
                  <td>
                    <b>
                      {toDateString(article.createdAt)}<br />
                      {toTimeString(article.createdAt)}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Autor:</td>
                  <td><b>{article.author}</b></td>
                </tr>
              </tbody>
            </table>
            {
              loading ?
                <div className="loading"><div></div><div></div><div></div></div>
              :
                <button className="btn w-100 mt-3" onClick={handleUpload}>
                  <FontAwesomeIcon className="mr-3" icon="paper-plane" />Veröffentlichen
                </button>
            }
          </Card>

          <Card title="Beitragsbild">
            {
              article.image ?
                <img src={article.image} alt="Beitragsbild"/>
              : article.photo ?
                <img src={URL.createObjectURL(article.photo)} alt="Beitragsbild"/>
              :
                <div className="no-article-image">
                  <p>Kein Beitragsbild</p>
                </div>
            }
            <input type="file" className="mt-3" onChange={handleImageChange} />
          </Card>
        </div>
      </div>
    </>
  )
};

export default ArticleCreate;