import React, { useContext, useState } from "react";
import { Pagetitle, Card, Modal } from "../../components";
import { GlobalContext } from "../../context/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import base from "../../config/base";
import { toast } from "react-toastify";

const Media = () => {
  const { images } = useContext(GlobalContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalFile, setModalFile] = useState();

  const fileUpload = () => {
    setModalLoading(true);
    console.log(modalFile);
    base.storage().ref('uploads').child(modalFile.name).put(modalFile).then(() => {
      toast.success('datei hochgeladen');
      setModalOpen(false);
      setModalLoading(false);
    })
  }

  return (
    <>
      <Pagetitle icon="photo-video" title="Mediathek" />

      <div className="mb-30">
        <button onClick={() => setModalOpen(true)} className="btn">
          <FontAwesomeIcon icon="plus" /> Datei hochladen
        </button>
      </div>

      <Card>
        <table className="hover">
          <thead>
            <tr>
              <th>Bild</th>
              <th>Ordner</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {
              images.get.map((item, i) => {
                return (
                  <tr key={i}>
                    <td><img src={item.url} alt="Bild" /></td>
                    <td>/uploads</td>
                    <td>{item.url}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </Card>

      <Modal title="Neue Datei" isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <input type="file" onChange={(e) => setModalFile(e.target.files[0])} />
        <br /><br />
        {
          modalLoading ? (
            <div className="loading"><div></div><div></div><div></div></div>
          ) : (
            <button className="btn mb-3" onClick={fileUpload}>Datei hochladen</button>
          )
        }
      </Modal>
    </>
  );
};

export default Media;
